@import "../../styles/variables";
@import "../../styles/mixins";

%h4 {
  font-family: $regular;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.5px;
  margin-bottom: 25px;
  color: #5d44a5;
}

.footer {
  background-color: $dark-purple;

  .logos {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 80px;

    img {
      margin: 0 10px;
    }

    .helmet {
      max-width: 60px;
    }

    .logo {
      max-width: 250px;
    }
  }

  .links {
    h4 {
      @extend %h4;
    }

    a {
      color: #000;
      text-decoration: none;
      font-size: 16px;
      letter-spacing: 1px;
      display: block;
      margin-bottom: 10px;
      transition: text-shadow 0.2s ease;

      &:hover {
        text-shadow: 0px 0px 15px rgba($color: $glowing-purple, $alpha: 1);
      }
    }
  }

  .DateAndTime{
    font-size: 20px;
    text-align: center;
    margin-top: 30px;
  }

  .center{
    text-align: center;
    padding: 20px;
    box-shadow: 0px 5px 12px magenta;
    background: linear-gradient(45deg, #da07a2 0%, #5d44a5 47%, #35b0e6 100%);
    border-radius: 11px;
    margin: 20px auto;
    
    span {
      font-size: 45px;
    }
  }

  .timer{
    text-align: center;
    margin-top: 30px;
  }

  .timerText{
    text-align: center;
    text-shadow: 0px 2px 12px magenta;
    margin-bottom: 20px;
    font-size: 36px;

    @include media-breakpoint-range (tab-up) {
      font-size: 40px;
    }
  }

  .timerText2 {
    @extend .timerText;
    font-size: 36px;
  }

  .social {
    h4 {
      @extend %h4;
    }
    h5 {
      font-family: $regular;
      font-size: 16px;
      font-weight: normal;
      margin-top: 40px;
      line-height: 24px;
      text-transform: none;
    }
    .buttons {
      a {
        &:not(:first-child) {
          margin-left: 16px;
        }
      }
      button {
        img {
          width: 24px;
        }
      }
    }
  }

  .sectionTimer{
    overflow: hidden;
  }
  

  .containerTimer{
    justify-content: center;
    margin-top: -600px;
    margin-bottom: 13%;
  }
}
