@import "./mixins";

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: $regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $bold;
  text-transform: uppercase;
}
.white-color{
  color: #fff! important;
}
section {
  padding-top: 40px;
  padding-bottom: 40px;
  @include media-breakpoint-range(tab-up) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

h2.section-title {
  font-family: $regular;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 40px;
  font-size: 34px;
  color: #5d44a5;
  line-height: 46px;

  @include media-breakpoint-range(tab-up) {
    font-size: 42px;
    line-height: 54px;
  }
}

h2.section-title-no-glow {
  font-family: $regular;
  letter-spacing: 7px;
  margin-top: 30px;
  margin-bottom: 40px;
  font-size: 34px;
  line-height: 46px;

  @include media-breakpoint-range(tab-up) {
    font-size: 42px;
    line-height: 54px;
  }
}



.text-pink {
  color: #df05ff;
}
