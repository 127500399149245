@import "../../styles/variables";
@import "../../styles/mixins";

.whole{
  color: #000;
  &:hover {
    cursor: pointer;
    color: $glowing-purple;
  }
}


.iconcard{
  background-color: #000;
}


.card {
  background-color: #fff;
  border: 1px solid #000;
  padding: 20px;
  @include media-breakpoint-range (tab-up) {
    padding: 40px;
  }
 
  margin-left: 20px;
  margin-right:20px;
  .des{
    // height: 150px;
    margin-bottom: 10px;
  }
  .badge-pink {
    background-color: $pink;
    color: $tab-button;
    height: 20px;
    border-radius: 10px;
    padding: 0 10px;
    font-size: 14px;
  }

  .badge-blue {
    background-color: $blue;
    color: $tab-button;
    height: 20px;
    border-radius: 10px;
    padding: 0 10px;
    font-size: 14px;
  }

  .participants {
    color: #0ae8e9;
  }

  // .logo {
  //   img {
  //     max-width: 150px;
  //     margin: 0 auto 16px;
  //     display: block;
  //     // height: 72px;

  //     @include media-breakpoint-range (desktop-up) {
  //       max-width: none;
  //     }
  //   }
  // }
  
  &.top {
    border-radius: 20px 20px 0 0;
    height: auto;
    // border-bottom: 5px solid rgba($color: white, $alpha: .1);
  }
  .maruImg{
    margin:0 ;
    width: 100%;
    max-height: 150px;
  }
  .twinImg{width:50%;height:auto}
  @media screen and (max-width: 580px) {
    &.bottom {
      border-radius: 0 0 20px 20px;
      margin-bottom: 30px;
      height: auto!important;
    }
    .twinImg{width:20%;height:auto}
  }
  &.bottom {
    border-radius: 0 0 20px 20px;
    margin-bottom: 30px;
    height: 400px;
  }
  

  .social {
    margin-top: 0px!important;
    button {
      margin: 0 9px;
      img {
        width: 24px;
      }
    }
  }

  .note {
    font-size: 15px;
    margin-bottom: 20px;
  }

  .label {
    font-size: 22px;
  }

  .value {
    font-size: 28px;
  }
  
}
